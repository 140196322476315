import React, { useEffect, useState } from 'react'
import "antd/dist/antd.css";
import { Row, Col, Dropdown, Menu, Button } from 'antd';
import { DownOutlined, LoginOutlined } from '@ant-design/icons';
import flagUs from '../images/us-fl.jpeg'
import cws from '../images/cws-fl.jpeg'
import rsc from '../images/rsc-fl.jpeg'
import "./header.css";
import { Link } from 'react-router-dom';
import { uploadGallery } from '../store/actions/galleryActions.js';
import { changeLanguage } from "../store/actions/languageActions";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory } from 'react-router';
import { setUser } from "../store/actions/userActions";

const header = {
    padding: "50px",
    height: "320px"
}

const headerMobile = {
    marginTop: "35px",
    height: "200px"
}

const leftCol = {
    textAlign: "start"
}

const middleCol = {
    textAlign: "center"
}

const rightCol = {
    textAlign: "end"
}

export const HeaderSection = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    //check if there is user
    const user = useSelector(state => state.user.user);;
    const [photos, setPhotos] = useState([]);

    const redirect = (e) => {
        e.preventDefault();

        if (user) {
            history.push('/status');
        } else {
            window.location.replace("/#cnum");
        }
    }

    //active link  
    const setActive = (e) => {

        var btn = document.getElementById("active");
    
        if (btn !== null) {
            btn.removeAttribute('id');
        }
        e.target.id = "active";
    }

    //remove actice link
    const removeActive = (e) => {
    
        var btn = document.getElementById("active");
        if (btn !== null) {
            btn.removeAttribute('id');
        }
    }
  
    //log out
    const logOut = () => {
        //remove user from store or token
        dispatch(setUser(''));
        history.push('/');
    }

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_medium = useMediaQuery(theme.breakpoints.up('sm'))
    const matches_large = useMediaQuery(theme.breakpoints.down('md'))
    const [currentLanguage, setCurrentLanguage] = useState("Choose Language");

    const handleLanguageClick = ({ key }) => {
        setCurrentLanguage(key);
        dispatch(changeLanguage(key));
    }

    const myCaseMenu = (
        <Menu>
            <Menu.Item key="1" >
                <Link to='/status' className="menu-item">How Can I help MyCase</Link>
            </Menu.Item>
            <Menu.Item key="2" >
                <Link to="/status" className="menu-item">Updates to MyCase</Link>
            </Menu.Item>
        </Menu>
    );

    const lang = (
        <Menu onClick={handleLanguageClick}>
            <Menu.Item key="English">English</Menu.Item>
            <Menu.Item key="Kiswahili">Kiswahili</Menu.Item>
            <Menu.Item key="Somali">Somali</Menu.Item>
            <Menu.Item key="Francaise">Francaise</Menu.Item>
            <Menu.Item key="Arabic">عربى</Menu.Item>
            <Menu.Item key="Amharic">ቋንቋ ለውጥ</Menu.Item>
            <Menu.Item key="Kinyarwanda">Kinyarwanda</Menu.Item>
            <Menu.Item key="Kirundi">Kirundi</Menu.Item>
            <Menu.Item key="Tigrinya">ትግርኛ</Menu.Item>
            <Menu.Item key="Oromo">Oromo</Menu.Item>
        </Menu>
    );

   /* const fetchData = () => {
    
        //getting sms data from drive sheet 
        Tabletop.init({
            key: '19_ZsP7fNAgjaObkRTGISutIMvfejV0QuI-cpUSHEHCE',
            callback: googleData => {
                //updating store
                if (googleData.length > 2) {
                    dispatch(uploadGallery(googleData));
                    setPhotos(googleData);
                }
            },
            simpleSheet: true
        })
    }*/

    const menu = (
        <Menu>
            <Menu.Item>
                {user ? (<Link onClick={logOut} className="nav-link" > <h4 className="nav-link"><LoginOutlined />Log Out</h4></Link>
                ) : (<Link onClick={redirect} className="nav-link" > <h3 className="nav-link">Check MyCase </h3></Link>
                )}
            </Menu.Item>
            <Menu.Item>
                <Link to='/refugee-resources'> <h3 className="nav-link">Refugee Resources</h3></Link>
            </Menu.Item>
                {/* <Menu.Item>
                        <Link to='/public-resources'> <h3 className="nav-link">Public Resources</h3></Link>
                    </Menu.Item> */}
                {/* <Menu.Item>
                        <Link to='/gallery'> <h3 className="nav-link" onClick={fetchData}>Gallery</h3></Link>
                    </Menu.Item> */}
            <Menu.Item>
                <Link to='/contact-us' className="nav-link"><h3 className="nav-link">Contact Us</h3></Link>
            </Menu.Item>
        </Menu>
    );

   /* useEffect(() => {
      // fetchData();
    });*/

    // Conditional Rendering for mobile screen, medium screen and large screen

    //Render mobile screen
    if (matches) {
        return (
            <>
                <div style={headerMobile}>
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} style={{ padding:"20px" }}>
                        <Col span={8} style={leftCol}><img src={flagUs} height={40} /></Col>
                        <Col span={8} style={middleCol}><Link to='/'><img src={rsc} height={45} /></Link></Col>
                        <Col span={8} style={rightCol}><img src={cws} height={40} /></Col>
                    </Row>
                    <Row style={{ marginTop:"50px", padding:"15px" }}>
                        <Col span={2} style={leftCol}>
                            <Dropdown overlay={menu} >
                                <MenuIcon style={{ fontSize: "50px" }} />
                            </Dropdown>
                        </Col>
                        <Col span={12} offset={8} style={rightCol}>
                            <h3>
                                <Dropdown overlay={lang}>
                                    <Button style={{ borderColor: "#0075B2", backgroundColor: "#0075B2", fontWeight: "bold", color: "white", fontSize: "17px", height: "50px", verticalAlign: "middle" }}>
                                        {currentLanguage} <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </h3>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    // Render Medium screen
    if (matches_medium && matches_large) {
        return (
            <>
                <div style={header} >
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} onClick={removeActive}>
                        <Col span={8} style={leftCol}><img src={flagUs} height={70} /></Col>
                        <Col span={8} style={middleCol}><Link to='/'><img src={rsc} height={120} /></Link></Col>
                        <Col span={8} style={rightCol}><img src={cws} height={70} /></Col>
                    </Row>
                    <Row  style={{ paddingTop: "40px", textAlign: "center" }}  onClick={setActive} >
                        <Col span={5}>                 
                        <Link onClick={redirect} className='nav-span' id='active'  >Check MyCase</Link>
                        </Col>
                        <Col span={5}><Link to='/refugee-resources' className='nav-span' >Refugee Resources</Link></Col>
                        <Col span={5}><Link to='/contact-us' className="nav-span" >Contact Us</Link></Col>
                         {user?(<Col span={4}><Link onClick={logOut} className='nav-span-large' ><LoginOutlined />Log Out</Link></Col>):("")}
                        <Col span={5}>
                            <h2>
                                <Dropdown overlay={lang}>
                                    <Button style={{ borderColor: "#0075B2", backgroundColor: "#0075B2", fontWeight: "bolder", color: "white", fontSize: "14px", height: "100%"}}>
                                        {currentLanguage} <DownOutlined />
                                    </Button>
                                </Dropdown>
                            </h2>
                        </Col>
                    </Row>
                </div>
            </>
        )
    }

    //Otherwise render large screen
    return (
        <>
            <div style={header}   >
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }} onClick={removeActive}>
                    <Col span={8} style={leftCol}><img src={flagUs} height={70} /></Col>
                    <Col span={8} style={middleCol}><Link to='/' ><img src={rsc} height={120} /></Link></Col>
                    <Col span={8} style={rightCol}><img src={cws} height={70} /></Col>
                </Row>
                <Row  style={{ paddingTop: "70px", textAlign: "center"}} onClick={setActive}>
                    <Col span={5}>
                        
                        <Link onClick={redirect} className='nav-span-large' id='active'  >Check MyCase</Link>
                        
                    </Col>
                    <Col span={5}><Link to='/refugee-resources' className="nav-span-large">Refugee Resources</Link></Col>
                    {/* <Col span={4}><Link to='/public-resources' className="nav-span-large">Public Resources</Link></Col> */}
                    {/* <Col span={4}><Link to='/gallery' className="nav-span-large" >Gallery</Link></Col> */}
                    <Col span={5}><Link to='/contact-us' className="nav-span-large" id=''>Contact Us</Link></Col>
                     {user?(<Col span={4}><Link onClick={logOut} className='nav-span-large' ><LoginOutlined />Log Out</Link></Col>):("")}
                    <Col span={5}>
                        <h2>
                            <Dropdown overlay={lang}>
                                <Button style={{ borderColor: "#0075B2", backgroundColor: "#0075B2", fontWeight: "bold", color: "white", fontSize: "17px", height: '100%',  verticalAlign: "middle" }}>
                                    {currentLanguage} <DownOutlined />
                                </Button>
                            </Dropdown>
                        </h2>
                    </Col>
                   
                </Row>
            </div>
        </>
    )
}