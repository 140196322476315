import React from 'react'
import { Card, Col } from 'antd';
import './messages_template.css'
import { Link } from 'react-router-dom'

export const MessagesTemplate = ({ title, date, body,id}) => {

    return (
        <>
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={8} style={{ marginBottom: "30px", padding: "20px" }}>
                <Card className='card'>
                    <p className='card-title'>{title}</p>
                    <p className='card-date'>{date}</p>
                    <p className='card-body'>{body}</p>
                    <p><Link to={`/message/${id}`}><button className='read-more-button' style={{ color: "black", fontWeight: "bold" }}>READ MORE</button></Link></p>
                </Card>
            </Col>
        </>
    )
}
