import React, { useState, useEffect } from 'react'
import Card from '@material-ui/core/Card';
import { Row, Col, Dropdown, Menu, Button, Pagination } from 'antd';
import '../pages/styles/refugee_resources.css'
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import app_free from '../images/app_free.jpeg'
import app_store from '../images/app_store.png'
import desktop from '../images/desktop.png'
import dna from '../images/dna.jpeg'
import learn_eng from '../images/learn_eng.jpeg'
import settle from '../images/settle.png'
import p3 from '../images/p3.jpeg'
import patient from '../images/patient.jpeg'
import play_store from '../images/google_play.png'
import spouse from '../images/spouse.jpeg'
import us_reset_process from '../images/us_reset_process.jpeg'
import wat_can from '../images/wat_can.jpeg'
import dos from '../images/dos.jpeg'
import pay from '../images/pay.jpeg'
import prep from '../images/prep.jpeg'
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { VideoScrollTemplate } from '../components/video_scroll_template';
import { MessagesTemplate } from '../components/messages_template'
import { Link } from 'react-router-dom';
import data from '../components/fixtures';
//pdfs
import english_pdf from '../pdf/English.pdf'
import francaise_pdf from '../pdf/Francaise.pdf'
import kinyarwanda_pdf from '../pdf/Kinyarwanda.pdf'
import somali_pdf from '../pdf/Somali.pdf'
import kiswahili_pdf from '../pdf/Kiswahili.pdf'
import oromo_pdf from '../pdf/Oromo.pdf'
import { PdfResourceTemplate } from '../components/pdf_resource_template';
import { setMessages } from '../store/actions/messagesActions';
import { useDispatch } from 'react-redux';



    //fixtures to come from API
    var featureResource = {
        title: "USRAP is Free",
        img: app_free,
        pdfUrl: [ oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
        languages: [ 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise'],
        body: 'Access to the United States Refugee Admissions Program is FREE. It is expressively forbidden for any RSC Africa employee or partner to accept money or any favors from refugees for any reason. If an RSC Africa employee, or anyone claiming to have access to RSC Africa, demands payment in exchange for their services, please report them immediately to Fraud@CWSAfrica.org'
    }

    //fixtures to come from API
    var pdResources = [
        {
            title: "USRAP Application Process",
            ftImage: us_reset_process,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
        {
            title: "P3 Application Process",
            ftImage: p3,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
        {
            title: "DNA Testing is Easy",
            ftImage: dna,
            pdfUrl: [english_pdf, kiswahili_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['English', 'Kiswahili']
        },
        {
            title: "USRAP is Long",
            ftImage: patient,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
        {
            title: "USRAP is Free",
            ftImage: app_free,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
        {
            title: "What can you do to help RSC Africa with your case",
            ftImage: wat_can,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
        {
            title: "Learning English Will Help You",
            ftImage: learn_eng,
            pdfUrl: [english_pdf, kiswahili_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['English', 'Kiswahili']
        },
        {
            title: "Polygamy",
            ftImage: spouse,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
        {
            title: "Preparing For Travel",
            ftImage: prep,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(225,129,23)",
            textColor: "black",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
        {
            title: "Do's and Don'ts",
            ftImage: dos,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
        {
            title: "What To Expect",
            ftImage: pay,
            pdfUrl: [english_pdf, oromo_pdf, kinyarwanda_pdf, kiswahili_pdf, somali_pdf, francaise_pdf],
            buttonColor: "rgb(37, 71, 134)",
            textColor: "white",
            languages: ['English', 'Kiswahili', 'Oromo', 'Kinyarwanda', 'Somali', 'Francaise']
        },
    ]



const card = {
    margin: "50px",
    padding: "25px 140px 25px 120px"
}

const cardMobile = {
    margin: "15px",
    padding: "25px 25px 25px 25px"
}

const cardMedium = {
    margin: "50px",
    padding: "25px 120px 25px 120px"
}

const travel = {
    paddingTop: "25px"
}

//Reusable menu
export const LanguageMenu = ({ handleLanguageClick, languages, all_pdf_urls, handleLanguageChange }) => {
  
    return (
    
        <Menu onClick={handleLanguageClick} onChange={handleLanguageChange(all_pdf_urls)} >
            {languages.map(lang => (
                <Menu.Item key={lang}  > {lang}
                </Menu.Item>
            ))}
        </Menu>
    )
}

export const RefugeeResources = () => {

    const dispatch = useDispatch();

    //Varaibles to store api data
    const[featuredResource, setfeaturedResource] = useState(featureResource);
    const[pdfResources,setpdfResources] = useState(pdResources);
    const[messages,setMessages] = useState(data);

    //state to manage PDF display on mobile
    const [pagesCountMobile, setpagesCountMobile] = useState(1);
    const [pdfPerPageMobile, setPdfPerPageMobile] = useState(3);
    const [offsetMobile, setOffsetMobile] = useState(0);
    const [currentPagePdfMobile, setCurrentPagePdfMobile] = useState([])

    //state to manage PDF display on large screen
    const [pagesCountLarge, setpagesCountLarge] = useState(1);
    const [pdfPerPageLarge, setPdfPerPageLarge] = useState(9);
    const [offsetLarge, setOffsetLarge] = useState(0);
    const [currentPagePdfLarge, setCurrentPagePdfLarge] = useState([])

    //pdf to download state
    const [pdfToDownload, setPdfToDownLoad] = useState("");

    

    //pagination methods
    useEffect(() => {
        setPaginationStatesMobile();
        setPaginationStatesLarge();
    }, [])

    useEffect(() => {
        setPdfForCurrentPage();
        setPdfForCurrentPageLarge();
    }, [offsetMobile, offsetLarge])

    const handlePageClickMobile = (pageNumber) => {
        const currentPage = pageNumber - 1;
        const off = currentPage * pdfPerPageMobile;
        setOffsetMobile(off);
    }

    const handlePageClickLarge = (pageNumber) => {
        const currentPage = pageNumber - 1;
        const off = currentPage * pdfPerPageLarge;
        setOffsetLarge(off);
    }

    const [languageSelected, setLanguage] = useState('Select Language Download');
    const [pdfDownload, setDownload] = useState('');

    //handle click for languages on each single pdf
    const handleLanguageClick = ({ key }) => {
        setLanguage(key);
    }

    //handle change for languages on each single pdf
    const handleLanguageChange = (pdf_urls) => {
    
        let search_res;
        let pdf_to_down;
    
        for (var i = 0; i < pdf_urls.length; i++) {

            search_res = pdf_urls[i].toLowerCase().search(languageSelected.toLowerCase());

            if (search_res != -1) {
                pdf_to_down = pdf_urls[i];
                break;
            }
        }

        setPdfToDownLoad(pdf_to_down);
    }

    const resetLanguageState = () => {
        setLanguage("Select Language Download")
    }


    const [selectedVideo, setCurrentVideoSelected] = useState("item1")
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const matches_medium = useMediaQuery(theme.breakpoints.up('sm'))
    const matches_large = useMediaQuery(theme.breakpoints.down('md'))
    const matches_extra = useMediaQuery('(max-width:480px)');

    // list of videos
    const video_list = [
        { title: 'video1', video_url: "" },
        { title: 'video2', video_url: "" },
        { title: 'video3', video_url: "" },
        { title: 'video4', video_url: "" }
    ];

    const onSelect = key => {
        setCurrentVideoSelected(key);
    }

    const videoMenu = (list, selected) =>

        list.map(el => <VideoScrollTemplate title={el.title} video_url={el.video_url} selected={selected} /> );

        const Arrow = ({ icon, className }) => {
            return (
                <div className={className}>{icon}</div>
            );
        };

    // const ArrowLeft = Arrow({ icon: <ArrowBackIosIcon style={{ fontSize: "52px" }} />, className: 'arrow-previous' });
    // const ArrowRight = Arrow({ icon: <ArrowForwardIosIcon style={{ fontSize: "52px" }} />, className: 'arrow-forward' });
    // const menu_vids = videoMenu(video_list, selectedVideo)

    //states for mobile view to be able to access pdfResources
    const [totalPdfCount, setTotalPdfCount] = useState(pdfResources.length);

    const setPdfForCurrentPage = () => {
        const currentPageMes = pdfResources.slice(offsetMobile, offsetMobile + pdfPerPageMobile);
        setCurrentPagePdfMobile(currentPageMes);
    }

    const setPdfForCurrentPageLarge = () => {
        const currentPageMes = pdfResources.slice(offsetLarge, offsetLarge + pdfPerPageLarge);
        setCurrentPagePdfLarge(currentPageMes);
    }

    const setPaginationStatesMobile = () => {
        setpagesCountMobile(Math.ceil(totalPdfCount / pdfPerPageMobile))
        setPdfForCurrentPage()
    }

    const setPaginationStatesLarge = () => {
        setpagesCountLarge(Math.ceil(totalPdfCount / pdfPerPageLarge))
        setPdfForCurrentPageLarge()
    }

    //api call

    const getFeaturedResource = ()=>{
        //ajax here
        //setfeaturedResource(res.data)
    }

    const getPdfResources = ()=>{
        //ajax here
        //setpdfdResources(res.data)
    }

    const getMessages = ()=>{
         //ajax here
        //setMessages(res.data)
        //update to store to be accessed globally 
        //dispatch(setMessages(res.data))
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        getPdfResources();
        getFeaturedResource();
        getMessages();


    }, []);

    // Conditional Rendering for mobile screen, medium screen and large screen

    //Render mobile screen
    if (matches) {
        
        return (
            <>
                <Card style={cardMobile}>
                    <Row>
                        <Col span={24}>
                            <h1 className="fr-title-mobile">Featured Resource</h1>
                            <img src={featuredResource.img} className="fr-image" />
                        </Col>
                        <Col span={24}>
                            <p className="ft-title-right-mobile"> {featuredResource.title} </p>
                            <p>{featuredResource.body}</p>
                            
                            <Dropdown overlay={ 
                                <LanguageMenu 
                                    handleLanguageClick={handleLanguageClick} 
                                    handleLanguageChange={handleLanguageChange} 
                                    languages={featuredResource?.languages} 
                                    all_pdf_urls={featuredResource?.pdfUrl} />}>

                                <Button style={{ borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                    {languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ?  ('Select Language Download'):languageSelected} <DownOutlined />
                                </Button>
                            </Dropdown>
                            <br />
                            {
                                languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "20px" }}>
                                Download <DownloadOutlined /></Button>)
                            }
                        </Col>
                    </Row>
                </Card>

                <div style={travel}>
                    <h1 className="fr-travel-title-mobile">PDF Resources</h1>
                    <Row style={{ backgroundColor: "#e8e8e8", marginTop: "30px", padding: "20px" }}>
                        {
                            currentPagePdfMobile.map((res) => (
                                <PdfResourceTemplate title={res.title} languages={res.languages} handleLanguageChange={handleLanguageChange} languageSelected={languageSelected} ftImage={res.ftImage} resetLanguageState={resetLanguageState} pdfToDownload={pdfToDownload} buttonColor={res.buttonColor} textColor={res.textColor} handleLanguageClick={handleLanguageClick} all_pdf_urls={res.pdfUrl} />
                            ))
                        }
                    </Row>
                    {
                        pagesCountMobile > 0 &&
                        <Pagination
                            style={{ marginTop: 20, marginLeft: 25, fontSize: "23px" }}
                            defaultCurrent={1}
                            onChange={handlePageClickMobile}
                            size="small"
                            total={totalPdfCount}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Resources`}
                            pageSize={pdfPerPageMobile}
                            showSizeChanger={false}
                        />
                    }

                    <div style={{ backgroundColor: "#e8e8e8", padding: "20px" }}>
                        <div className='important-messages-section'>
                            <h1 className="fr-travel-title-mobile">Important Messages</h1>
                            <div className='messages-div'>
                                <Row>
                                    {messages.slice(0, 3).map((item, index) => (
                                        <MessagesTemplate title={item.title} date={item.date} body={item.body} id={item.id} />
                                    ))}
                                </Row>
                            </div>
                            <div className='view-more-div'><Link to='/important-messages'><button className='view-all-button'>VIEW ALL</button></Link></div>

                        </div>
                    </div>
                    {
                        matches_extra
                            ?
                            <div style={{ backgroundColor: "white", height: "600px" }}>
                                <Row>
                                    <Col span={24} style={{ padding: "30px", marginTop: "10px" }}>
                                        <p style={{ color: "black", fontWeight: "bold", fontSize: "24px" }}>Download the Settle InApp to learn more about resettlement in the US</p>

                                        <Row>
                                            <img src={app_store} height="30px" width="70px" style={{ marginRight: "5px", objectFit: 'cover' }} />
                                            <img src={play_store} height="30px" width="70px" style={{ marginRight: "5px", objectFit: 'cover' }} />
                                            <img src={desktop} height="30px" width="70px" style={{ objectFit: 'cover' }} />
                                        </Row>
                                    </Col>
                                </Row>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <img src={settle} height="250px" width="200px" style={{ textAlign: "center" }} />
                                </Col>
                            </div>
                            :
                            <div style={{ backgroundColor: "white", height: "460px" }}>
                                <Row>
                                    <Col span={24} style={{ padding: "30px", marginTop: "10px" }}>
                                        <p style={{ color: "black", fontWeight: "bold", fontSize: "24px" }}>Download the SettleIn App to learn more about resettlement in the US
                                        </p>

                                        <Row>
                                            <img src={app_store} height="30px" width="70px" style={{ marginRight: "5px", marginTop: "3px", objectFit: 'cover' }} />
                                            <img src={play_store} height="30px" width="70px" style={{ marginRight: "5px", marginTop: "3px", objectFit: 'cover' }} />
                                            <img src={desktop} height="38px" width="70px" sytle={{ objectFit: 'cover' }} />
                                        </Row>
                                    </Col>
                                </Row>
                                <Col span={24} style={{ textAlign: "center" }}>
                                    <img src={settle} height="250px" width="200px" style={{ textAlign: "center" }} />
                                </Col>
                            </div>
                    }
                </div>
            </>
        )

    }

    //Render Medium Screen
    if (matches_large && matches_medium) {
    
        return (
            <>
                <Card style={cardMedium}>
                    <Row>
                        <Col span={10}>
                            <h1 className="fr-title">Featured Resource</h1>
                            <img src={featuredResource.img} className="fr-image" />
                        </Col>
                        <Col span={4}></Col>
                        <Col span={10}>
                            <p className="ft-title-right-medium">{featuredResource.title}</p>
                            <p>{featuredResource.body}</p>

                            <Dropdown overlay={
                                <LanguageMenu 
                                    handleLanguageClick={handleLanguageClick} 
                                    handleLanguageChange={handleLanguageChange} 
                                    languages={featuredResource?.languages} 
                                    all_pdf_urls={featuredResource?.pdfUrl} />}>

                                <Button style={{ borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                    {languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ?  ('Select Language Download'):languageSelected} <DownOutlined />
                                </Button>
                            </Dropdown>
                            <br />
                            {
                                languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "20px" }}>
                                    Download <DownloadOutlined /></Button>)
                            }
                        </Col>
                    </Row>
                </Card>

                <div style={travel}>
                    <h1 className="fr-travel-title">PDF Resources</h1>
                    <Row style={{ backgroundColor: "#e8e8e8", marginTop: "30px", padding: "20px" }}>
                    {
                    currentPagePdfMobile.map((res) => (
                        <PdfResourceTemplate title={res.title} languages={res.languages} handleLanguageChange={handleLanguageChange} languageSelected={languageSelected} ftImage={res.ftImage} resetLanguageState={resetLanguageState} pdfToDownload={pdfToDownload} buttonColor={res.buttonColor} textColor={res.textColor} handleLanguageClick={handleLanguageClick} all_pdf_urls={res.pdfUrl} />
                    ))
                    }
                    </Row>
          
                    {
                        pagesCountMobile > 0 &&
                        <Pagination
                            style={{ marginTop: 20, marginLeft: 25, fontSize: "23px" }}
                            defaultCurrent={1}
                            onChange={handlePageClickMobile}
                            size="small"
                            total={totalPdfCount}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Resources`}
                            pageSize={pdfPerPageMobile}
                            showSizeChanger={false}
                        />
                    }

                    <div style={{ backgroundColor: "#e8e8e8", padding: "40px" }}>
                        <div className='important-messages-section'>
                            <h1 className="fr-travel-title">Important Messages</h1>
                            <div className='messages-div'>
                                <Row>
                                    <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10} >
                                        <h1 className="fr-title">Featured Resource</h1>
                                        <img src={featuredResource.img} className="fr-image" />
                                    </Col>
                                    <Col span={1}></Col>
                                    <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10}>
                                        <p className="ft-title-right">{featuredResource.title}</p>
                                        <p>{featuredResource.body} </p>

                                        <Dropdown overlay={
                                            <LanguageMenu 
                                                handleLanguageClick={handleLanguageClick} 
                                                handleLanguageChange={handleLanguageChange} 
                                                languages={featuredResource?.languages} 
                                                all_pdf_urls={featuredResource?.pdfUrl} />}>

                                            <Button style={{ borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                                {languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('Select Language Download') : ("")}  <DownOutlined />
                                            </Button>
                                        </Dropdown>
                                        <br />
                                        {
                                            languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "20px" }}>
                                            Download <DownloadOutlined /></Button>)
                                        }
                                    </Col>
                                    <Col span={4}></Col>
                                </Row>
                            </div>

                            <div className='view-more-div'><Link to='/important-messages'><button className='view-all-button'>VIEW ALL</button></Link></div>
                        </div>
                    </div>
          
                    <div style={{ backgroundColor: "white", height: "400px" }}>
                        <Row>
                            <Col span={14} style={{ padding: "30px", marginTop: "20px" }}>
                                <p style={{ color: "black", fontWeight: "bold", fontSize: "24px" }}>Download the SettleIn App to learn more about resettlement in the US</p>

                                <Row>
                                    <img src={app_store} height="50px" width="150px" style={{ marginRight: "5px", marginTop: "3px", objectFit: 'cover' }} />
                                    <img src={play_store} height="50px" width="150px" style={{ marginRight: "5px", marginTop: "3px", objectFit: 'cover' }} />
                                    <img src={desktop} height="55px" width="150px" style={{ objectFit: 'cover' }} />
                                </Row>
                            </Col>
                            <Col span={4} offset={2} style={{ textAlign: "center" }}>
                                <img src={settle} height="350px" width="350px" style={{ marginTop: "55px" }} />
                            </Col>
                        </Row>
                    </div>
                </div>
            </>
        )
    }

    //Otherwise render large screen
    return (
        <>
            <Card style={card}>
                <Row>
                    <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10} >
                        <h1 className="fr-title">Featured Resource</h1>
                        <img src={featuredResource.img} className="fr-image" />
                    </Col>
                    <Col span={1}></Col>
                    <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10}>
                        <p className="ft-title-right">{featuredResource.title}</p>
                        <p>{featuredResource.body} </p>

                        <Dropdown overlay={
                            <LanguageMenu 
                                handleLanguageClick={handleLanguageClick} 
                                handleLanguageChange={handleLanguageChange} 
                                languages={featuredResource?.languages} 
                                all_pdf_urls={featuredResource?.pdfUrl} />}>

                            <Button style={{ borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                {languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ?  ('Select Language Download'):languageSelected} <DownOutlined />
                            </Button>
                        </Dropdown>
                        <br />
                        {
                            languageSelected == "Select Language Download" || !featuredResource?.pdfUrl.includes(pdfToDownload) ? ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: "rgb(225,129,23)", backgroundColor: "rgb(225,129,23)", fontWeight: "bold", color: "black", fontSize: "17px", height: "50px", marginTop: "20px" }}>
                                Download <DownloadOutlined /></Button>)
                        }
                    </Col>
                    <Col span={4}></Col>
                </Row>
            </Card>
      
            <div style={travel}>
                <h1 className="fr-travel-title">PDF Resources</h1>
                <Row style={{ backgroundColor: "#e8e8e8", marginTop: "30px" }}>
                {
                    currentPagePdfLarge.map((res) => (
                        <>
                            <PdfResourceTemplate title={res.title} languages={res.languages} handleLanguageChange={handleLanguageChange} languageSelected={languageSelected} ftImage={res.ftImage} resetLanguageState={resetLanguageState} pdfToDownload={pdfToDownload} buttonColor={res.buttonColor} textColor={res.textColor} handleLanguageClick={handleLanguageClick} all_pdf_urls={res.pdfUrl} />
                        </>
                    ))
                }
                </Row>
                {
                    pagesCountLarge > 0 &&
                    <Pagination
                        style={{ marginTop: 20, marginLeft: 25, fontSize: "23px" }}
                        defaultCurrent={1}
                        onChange={handlePageClickLarge}
                        size="small"
                        total={totalPdfCount}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Resources`}
                        pageSize={pdfPerPageLarge}
                        showSizeChanger={false}
                    />
                }

                <div style={{ backgroundColor: "#e8e8e8", padding: "20px" }}>
                    <div className='important-messages-section'>
                        <h1 className="fr-travel-title">Important Messages</h1>
                        <div className='messages-div'>
                            <Row>
                                {messages.slice(0, 3).map((item, index) => (
                                    <MessagesTemplate title={item.title} date={item.date} body={item.body} id={item.id} />
                                ))}
                            </Row>
                        </div>
                        <div className='view-more-div'><Link to='/important-messages'><button className='view-all-button'>VIEW ALL</button></Link></div>
                    </div>
                </div>

                <div style={{ height: "400px", backgroundColor: "white" }} id="bottom-div">
                    <Row>
                        <Col span={14} style={{ padding: "30px", marginTop: "20px" }}>
                            <p style={{ color: "black", fontWeight: "bold", fontSize: "24px" }}>Download the SettleIn App to learn more about resettlement in the US</p>
              
                            <Row >
                                <img src={app_store} height="60px" width="140px" style={{ marginRight: "10px", marginTop: "5px", objectFit: 'cover' }} />
                                <img src={play_store} height="60px" width="140px" style={{ marginRight: "10px", marginTop: "5px", objectFit: 'cover' }} />
                                <img src={desktop} height="69px" width="140px" style={{ objectFit: 'cover' }} />
                            </Row>
                        </Col>
                        <Col span={2} offset={4}>
                            <img src={settle} height="360px" width="260px" style={{ marginTop: "60px", objectFit: 'cover' }} />
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}
