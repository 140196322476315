import React,{useState} from 'react';
import './styles/contactUs.css';
import Swal from 'sweetalert2';
import {Loader} from '../components/loader.jsx';


const axios = require('axios');
const image_url="images/";
const swalReloadAlert = (text,icon,color)=>{
    Swal.fire({
           width: 400,
           height: 5,
           showConfirmButton: true,
           showCancelButton: false,
           closeOnConfirm: true,
           closeOnCancel: true,
           confirmButtonText: 'OK',
           confirmButtonColor: '#ea9b0a',
           confirmButtonBorderColor: 'none',
           cancelButtonText: 'Cancel',
           text: text,
           icon: icon,
           iconColor:color,
           iconHeight: 5,
           confirmButtonText: 'close'
       }).then(function () {
        window.location.reload();
      });;
}

export const ContactUs = () => {
    //vairable collects form inputs
    const[emailBody,setEmail] = useState({
        casenumber:'', emailAddress:'',topic:'',message:''
    });
    const [isPending, setPending] = useState(false);

    function clearForm() {
        document.getElementById("contact-form").reset();
    }

    function sendEmail(e) {
        e.preventDefault();
        //api call here
        setPending(true)
        axios.post(process.env.REACT_APP_API_URL+'applicant/contact-us', {
            emailBody
        },
        {
            headers: {
              Authorization: process.env.REACT_APP_API_TOKEN
            }
          }).then(function (response) {
           setPending(false)
            if(response.data.status === true) {
                return Swal.fire({
                    width: 400,
                    height: 5,
                    showConfirmButton: true,
                    showCancelButton: false,
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#ea9b0a',
                    confirmButtonBorderColor: 'none',
                    cancelButtonText: 'Cancel',
                    text: 'Message successfully sent. You shall recieve a response as soon as possible!',
                    icon: 'success',
                    iconColor:'#ff0000',
                    iconHeight: 5,
                    confirmButtonText: 'close'
                });
            } else {
                return Swal.fire({
                    width: 400,
                    height: 5,
                    showConfirmButton: true,
                    showCancelButton: false,
                    closeOnConfirm: true,
                    closeOnCancel: true,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#ea9b0a',
                    confirmButtonBorderColor: 'none',
                    cancelButtonText: 'Cancel',
                    text: 'Error sending message. Please try again. If not successful, please email case@cwsafrica.org',
                    icon: 'error',
                    iconColor:'#ff0000',
                    iconHeight: 5,
                    confirmButtonText: 'close'
                });
            }
        }).catch(function (error) {
            return swalReloadAlert(error.response.data.error+'Please try again','error','#ff0000');
        });
    }

    const formHandler = (e) =>{
        e.preventDefault();
        setEmail({...emailBody,[e.target.name]:e.target.value});
    }

    React.useEffect(() => {
        window.scrollTo(0, 0);
        
    }, []);

    return (
        <div className='contact-us-main-container'> 
            <div className='contact-us-div'>
                <div><span id ='contact-us-title'>Contact Us</span></div>
                <div className='icons-div'><img src={image_url+"email-con.png"} className='contact-icons' /><span className='contact-txt'>Email – case@cwsafrica.org</span></div>
                <div className='icons-div1'><img src={image_url+"mail-icon.png"} className='contact-icons' /><span className='contact-txt'>Mail – P.O. Box 14176-00800</span></div>
            </div>
         
            <div className="form-div">
                <form id='contact-form'  onSubmit={sendEmail} >
                    <p><input type="text"  id='contact-input' placeholder="Case Number" name='casenumber'  onChange={formHandler} required/></p>
                    <p><input type="email" placeholder="Email Address" name="emailAddress"  onChange={formHandler} required id='contact-input'/></p>
 
                    <p>
                        <select name="topic" id="select" placeholder='Select Topic' onChange={formHandler}>
                            <option value="A">Select topic</option>
                            <option value="General Case Inquiry">General Case Inquiry</option>
                            <option value="Case Member Update"> Case Member Update</option>
                            <option value="Address Change">  Address Change</option>
                            <option value="Contact Change"> Contact Change</option>
                            <option value="Request for Review Submission">   Request for Review Submission</option>
                            <option value='Other'>  Other</option>
                        </select>
                    </p>

                    <p><textarea id='text-area' name='message' placeholder="Message" onChange={formHandler} required/></p>
                    <p><button  id='button' >{isPending?(<Loader/>):("")}SUBMIT</button></p>
                </form>
            </div>
        </div>
    );
}