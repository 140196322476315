import React, { useState, useEffect } from 'react'
import '../pages/styles/important_messages.css'
import { MessagesTemplate } from '../components/messages_template';
import { Row, Pagination } from 'antd';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import data from '../components/fixtures';
import { useSelector } from 'react-redux';



export const ImportantMessages = () => {

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));

    const [pagesCount, setPagesCount] = useState(1);
    const [totalMessagesCount, setTotalMessagesCount] = useState(data.length);
    const [messagesPerPage, setMessagesPerPage] = useState(9);
    const [offset, setOffset] = useState(0);
    const [currentPageMessages, setCurrentPageMessages] = useState([])

    //states for mobile view
    const [pagesCountMobile, setpagesCountMobile] = useState(1);
    const [messagesPerPageMobile, setmessagesPerPageMobile] = useState(3);
    const [offsetMobile, setOffsetMobile] = useState(0);
    const [currentPageMessagesMobile, setCurrentPageMessagesMobile] = useState([])

    useEffect(() => {
        setPaginationStates();
        setPaginationStatesMobile();
    }, [])

    useEffect(() => {
        setMessagesForCurrentPage();
        setMessagesForCurrentPageMobile();
    }, [offset, offsetMobile])

    const setMessagesForCurrentPage = () => {
        const currentPageMes = data.slice(offset, offset + messagesPerPage);
        setCurrentPageMessages(currentPageMes);
    }

    //mobile view
    const setMessagesForCurrentPageMobile = () => {
        const currentPageMes = data.slice(offsetMobile, offsetMobile + messagesPerPageMobile);
        setCurrentPageMessagesMobile(currentPageMes);
    }

    const handlePageClick = (pageNumber) => {
        const currentPage = pageNumber - 1;
        const off = currentPage * messagesPerPage;
        setOffset(off);
    }

    //mobile view
    const handlePageClickMobile = (pageNumber) => {
        const currentPage = pageNumber - 1;
        const off = currentPage * messagesPerPageMobile;
        setOffsetMobile(off);
    }

    const setPaginationStates = () => {
        setPagesCount(Math.ceil(totalMessagesCount / messagesPerPage))
        setMessagesForCurrentPage()
    }

    //mobile view
    const setPaginationStatesMobile = () => {
        setpagesCountMobile(Math.ceil(totalMessagesCount / messagesPerPage))
        setMessagesForCurrentPageMobile()
    }

    //now access messages from store
    //should be replaced with data and delete imported data fixtures
     const messages = useSelector(state => state.messages);

    React.useEffect(() => {
        window.scrollTo(0, 0);

    }, []);


    return (
        <>
            {
                matches
                    ?
                    <div style={{ backgroundColor: "#e8e8e8", padding: "10px" }}>
                        <div className='important-messages-section'>
                            <h1 className="fr-trav-title">Important Messages</h1>
                            <div className='messages-div'>
                                <Row>
                                    {currentPageMessagesMobile.map((item, index) => (
                                        <MessagesTemplate title={item.title} date={item.date} body={item.body} id={item.id} />
                                    ))}
                                </Row>
                            </div>
                            {
                                pagesCount > 0 &&
                                <Pagination
                                    style={{ marginTop: 20, marginLeft: 25, fontSize: "23px" }}
                                    defaultCurrent={1}
                                    onChange={handlePageClickMobile}
                                    size="small"
                                    total={totalMessagesCount}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Messages`}
                                    pageSize={messagesPerPageMobile}
                                    showSizeChanger={false}
                                />
                            }
                        </div>
                    </div>
                    :
                    <div style={{ backgroundColor: "#e8e8e8", padding: "10px" }}>
                        <div className='important-messages-section'>
                            <h1 className="fr-trav-title">Important Messages</h1>
                            <div className='messages-div'>
                                <Row>
                                    {currentPageMessages.map((item, index) => (
                                        <MessagesTemplate title={item.title} date={item.date} body={item.body} id={item.id} />
                                    ))}
                                </Row>
                            </div>
                            {
                                pagesCount > 0 &&
                                <Pagination
                                    style={{ marginTop: 20, marginLeft: 25, fontSize: "23px" }}
                                    defaultCurrent={1}
                                    onChange={handlePageClick}
                                    size="small"
                                    total={totalMessagesCount}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Messages`}
                                    pageSize={messagesPerPage}
                                    showSizeChanger={false}
                                />
                            }
                        </div>
                    </div>
            }

        </>
    )
}
