import React from 'react'
import { Col, Row } from 'antd';
import posterImg from '../images/footer.png';
import GetAppIcon from '@material-ui/icons/GetApp';
import './pdf_scroll_template.css'

export const PdfScrollTemplate = ({ title, pdf_url, selected }) => {

    return (
        <>
            <Col>
                <img src={posterImg} className="imgPos" />
                <hi className="pub">Public Resource</hi>
                <h2 style={{ marginLeft: "13px" }}>{title}</h2>
                <Row align="middle"><GetAppIcon style={{ color: "rgb(223,143,47)" }} /><h2 style={{ marginTop: "10px" }}>Download</h2></Row>
            </Col>
        </>
    )
}
