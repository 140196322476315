import React, { Fragment } from 'react'
import "antd/dist/antd.css";
import { Popover } from 'antd';
import footer from '../images/footer4.png'
import './footer.css';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';

const main = {
    backgroundColor: "black",
    padding: 0
}
const image_url="images/";

const core_content = (
    <div style={{ textAlign: "center" }}>
        <p>Click <a target='_blank' href='https://settleinus.org/' rel="noreferrer"> here</a>
            to access additional resources on<br /> the U.S. Refugee Admissions Program
        </p>
    </div>
);

const cws_content = (
    <div style={{ textAlign: "center" }}>
        <p>Click <a target='_blank' href='https://cwsglobal.org/our-work/africa/' rel="noreferrer">here</a>
        to know more about Church World Service<br /> and their work around the globe.
        </p>
    </div>
);

const core_title = (
    <span style={{ fontWeight: "bold" }}>SETTLE IN</span>
)

const cws_title = (
    <span style={{ fontWeight: "bold" }}>CWS</span>
)

const logo = {
    height: 80,
    width: 350,
}

const logoMobile = {
    height: 60,
    width: 280
}

const logo2 = {
    position: 'relative',
    height: '60%',
    width: 280,
    overflow: 'hidden',
    backgroundSize: 'contain',
    objectFit: 'cover',
}

const logo2Mobile = {
    position: 'relative',
    height: '40%',
    width: 240,
    marginLeft: 15,
    marginTop: 20,
    overflow: 'hidden',
    backgroundSize: 'contain',
    objectFit: 'cover'
}

const logo_mobile = {
    height: 150,
    width: 300,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    objectFit: 'cover',
    marginBottom: 40
}

export const FooterSection = () => {

    const history = useHistory();
    //check if there is user
    const user = useSelector(state => state.user.user);
  
    const redirect = (e) => {
        e.preventDefault();

        if (user) {
            history.push('/status');
        } else {
            window.location.replace("/#cnum");
        }
    }

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    
    // Conditional Rendering for mobile screen, medium screen and large screen

    //Render mobile screen
    if (matches) {
        return (
            <>
                <div style={main}>
                    <div className='mobile-fraudSection'>
                        <img src={footer} width="100%" height="400px" alt="fraud" className="fraud-image" />
                        <div className='mobile-fraud-txt'>
                            <p>
                                <span id='mobile-title'>Fraud Reporting</span>
                                <p>
                                    USRAP remains free of charge, and applicants should not engage with anyone asking them to<br />
                                    pay or provide services for information or a change in status on your case.<br />
                                    Please report any instances of fraud or abuse to the RSC Africa’s confidential email box at<br />
                                    <span style={{ fontWeight: 'bolder' }}>fraud@CWSAfrica.org</span>
                                </p>
                            </p>
                        </div>
                    </div>

                    <div className='mobile-operation'>
                        <span>
                            This website was funded by the United States Department of State under the authority of the Migration and Assistance Act of 1962, as amended.
                            The opinions, findings, and conclusions stated herein are those of the author and do not necessarily reflect those of the United
                            States Department of State.
                        </span>
                    </div>
                    <div className='core-logo'>
                        <div className='wcs-logo'>
                            <Popover content={cws_content} title={cws_title} placement="right" style={{ backgroundColor: "black" }}>
                                <img src={image_url+"flogos-CWS.png"} alt="cws" style={logoMobile} />
                            </Popover>
                        </div>
                        <div>
                            <Popover content={core_content} title={core_title} placement="right" style={{ backgroundColor: "black" }}>
                                <img src={image_url+"SettleIn.png"} alt="core" style={logo2Mobile} />
                            </Popover>
                        </div>
                    </div>
                    <div className='mobile-footer-last'>
                        <div>
                            <div className='footer-contacts'>
                                <span className='contact-title'>RSC AFRICA CONTACT INFORMATION:</span>
                                <p className='contact-info'>
                                    P.O. Box 14176-00800,<br />
                                    Nairobi, Kenya<br />
                                    Email: case@CWSAfrica.org
                                </p>
                            </div>
                        </div>
                    <div>

                    <div className='site-map'>
                        <span className='site-title'>SITEMAP</span>
                        <p className="siteMap">
                            <Link onClick={redirect} className='mobile-site-link'> Check MyCase</Link><br />
                            <Link to='/refugee-resources' className='mobile-site-link'>Refugee Resources</Link><br />
                                        {/* <Link to='/public-resources'  className='mobile-site-link'>Public Resources</Link><br />
                                        <Link to='/gallery'  className='mobile-site-link'>Gallery</Link><br /> */}
                            <Link to='/contact-us' className='mobile-site-link'>Contact Us</Link><br />
                        </p>
                    </div>
                </div>
            </div>
            </div>
            </>
        )
    }

    //Otherwise render this for medium and large screen
    return (
        <>
            <div style={main}>
                <div className='fraudSection'>
                    <img src={footer} width="100%" height="250px" className="fraud-image" />
                    <div className='fraud-txt'>
                        <span>
                            <span id='title'>Fraud Reporting</span>
                            <p>
                                USRAP remains free of charge, and applicants should not engage with anyone asking them to<br />
                                pay or provide services for information or a change in status on your case.<br />
                                Please report any instances of fraud or abuse to the RSC Africa’s confidential email box at<br />
                                <span style={{ fontWeight: 'bolder' }}>fraud@CWSAfrica.org</span>
                            </p>
                        </span>
                    </div>
                </div>

                <div className='operation'>
                    <span>
                        This website was funded by the United States Department of State under the authority of the Migration and Assistance Act of 1962, as amended.
                        The opinions, findings, and conclusions stated herein are those of the author and do not necessarily reflect those of the United
                        States Department of State.
                    </span>
                </div>

                <div className='footer-last'>
                    <div>
                        <span className='contact-title'>RSC AFRICA CONTACT INFORMATION:</span>
                        <p className='contact-info'>
                            P.O. Box 14176-00800,<br />
                            Nairobi, Kenya<br />
                            Email: case@CWSAfrica.org
                        </p>
                    </div>

                    <div className='site-map'>
                        <span className='site-title'>SITEMAP</span>
                        <p className="siteMap">
                            <Link onClick={redirect} className='site-link'> Check MyCase</Link><br />
                            <Link to='/refugee-resources' className='site-link'>Refugee Resources</Link><br />
                            {/* <Link to='/public-resources'  className='site-link'>Public Resources</Link><br />
                                                    <Link to='/gallery'  className='site-link'>Gallery</Link><br /> */}
                            <Link to='/contact-us' className='site-link'>Contact Us</Link><br />
                        </p>
                    </div>
                    <div>
                        <Popover content={cws_content} title={cws_title} placement="left" style={{ backgroundColor: "black" }}>
                            <img src={image_url+"flogos-CWS.png"} style={logo} />
                        </Popover>
                    </div>
                    <div>
                        <Popover content={core_content} title={core_title} placement="right" style={{ backgroundColor: "black" }}>
                            <img src={image_url+"SettleIn.png"} style={logo2} />
                        </Popover>
                    </div>
                </div>
            </div>
        </>
    )
}