import React, { useState, useEffect } from 'react';
import './styles/publicResources.css';
import { Row } from 'antd';
import { MapSection } from '../components/map.jsx';
import { MessagesTemplate } from '../components/messages_template';
import { Link } from 'react-router-dom'
import ScrollMenu from 'react-horizontal-scrolling-menu';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { PdfScrollTemplate } from '../components/pdf_scroll_template';
import data from '../components/fixtures';

export const PublicResources = () => {

    const [selectedPdf, setCurrentSelectedPdf] = useState("item1")

    const pdf_list = [
        { title: 'Resource 01', pdf_url: "" },
        { title: 'Resource 02', pdf_url: "" },
        { title: 'Resource 03', pdf_url: "" },
        { title: 'Resource 04', pdf_url: "" }
    ];

    const onSelect = key => {
        setCurrentSelectedPdf(key);
    }

    const pdfMenu = (list, selected) =>

        list.map(el =>
            <PdfScrollTemplate title={el.title} pdf_url={el.pdf_url} selected={selected} />
        );


    const Arrow = ({ icon, className }) => {
        return (
            <div className={className}>{icon}</div>
        );
    };

    React.useEffect(() => {
        window.scrollTo(0, 0);
        
    }, []);

    const ArrowLeft = Arrow({ icon: <ArrowBackIosIcon style={{ fontSize: "52px" }} />, className: 'arrow-previous' });
    const ArrowRight = Arrow({ icon: <ArrowForwardIosIcon style={{ fontSize: "52px" }} />, className: 'arrow-forward' });

    const menu_pdfs = pdfMenu(pdf_list, selectedPdf);

    return (
        <>
            <div className='important-messages-section'>
                <h1 id='message-tes-title'>Important Messages</h1>
                <div className='messages-div'>
                    <Row>
                        {data.slice(0, 3).map((item, index) => (
                            <MessagesTemplate title={item.title} date={item.date} body={item.body} id={item.id} />
                        ))}
                    </Row>
                </div>
                <div className='view-more-div'><Link to='/important-messages'><button className='view-all-button'>VIEW ALL</button></Link></div>
            </div>
            
            <div className="map-sectipn">
                <MapSection />
            </div>
            
            <div style={{ padding: "30px" }}>
                <h1 className="fr-res-title">Public Resources 01</h1>
                <ScrollMenu
                    data={menu_pdfs}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={selectedPdf}
                    onSelect={onSelect}
                    alignCenter={false}
                    className='ScrollMenu'
                />
            </div>

            <div style={{ padding: "30px" }}>
                <h1 className="fr-res-title">Public Resources 02</h1>
                <ScrollMenu
                    data={menu_pdfs}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={selectedPdf}
                    alignCenter={false}
                    onSelect={onSelect}
                />
            </div>
        </>
    )
}