import React from 'react'
import '../pages/styles/message_detail.css'
import { Col } from 'antd';
import { useParams } from "react-router-dom";
import data from "../components/fixtures";


export const MessageDetail = () => {

    //return message clicked 
    const { messageId } = useParams()
    const message = data.find(msg => msg.id === messageId);
    console.log('mseeg', message, messageId);

    React.useEffect(() => {
        window.scrollTo(0, 0);
        
    }, []);

    return (
        <>
            <div className="main-div">
                <Col>
                    <h1 className="mes-header">
                        {message.title}
                    </h1>
                    <h3 className="mes-date">{message.date}</h3>
                    <p className="mes-content">{message.body}</p>
                </Col>
            </div>
        </>
    )
}
