import React from 'react'
import '../pages/styles/refugee_resources.css'
import { Col, Dropdown, Button } from 'antd';
import { DownOutlined, DownloadOutlined } from '@ant-design/icons';
import { LanguageMenu } from '../pages/refugee_resources';


export const PdfResourceTemplate = ({ title, ftImage, buttonColor, textColor, handleLanguageChange, languageSelected, pdfToDownload, resetLanguageState, handleLanguageClick, all_pdf_urls, languages }) => {

    return (
        <>
            <Col xs={24} sm={24} md={24} lg={24} xl={8} xxl={8} style={{ textAlign: "center", marginTop: "30px" }}>
                <h1 className="pdf-res-titles">{title}</h1>
                <img src={ftImage} className="pdf-res-image" />
                <br />
                {
                    all_pdf_urls.length == 0  ?
                        null
                        :
                        <Dropdown overlay={<LanguageMenu handleLanguageClick={handleLanguageClick} handleLanguageChange={handleLanguageChange} languages={languages} all_pdf_urls={all_pdf_urls} />}>
                            <Button style={{ borderColor: buttonColor, backgroundColor: buttonColor, fontWeight: "bold", color: textColor, fontSize: "17px", height: "50px", marginTop: "10px" }}>
                                {languageSelected == "Select Language Download" || !all_pdf_urls.includes(pdfToDownload) ?  ('Select Language Download'):languageSelected}<DownOutlined />
                            </Button>
                        </Dropdown>
                }
                <br />
                {
                    languageSelected == "Select Language Download" || !all_pdf_urls.includes(pdfToDownload) ?  ('') : (<Button href={pdfToDownload} onClick={resetLanguageState} download style={{ textAlign: "center", borderColor: buttonColor, backgroundColor: buttonColor, fontWeight: "bold", color: textColor, fontSize: "17px", height: "50px", marginTop: "20px" }}>
                        Download <DownloadOutlined /></Button>)

                }
            </Col>
        </>
    )
}