import React from 'react'
import { LandingSection } from '../pages/landing'

export const Home = () => {

    React.useEffect(() => {
        window.scrollTo(0, 0);
        
    }, []);

   return (
        <>
            <LandingSection />
        </>
    )
}
