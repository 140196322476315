import { SET_USER} from "../actions/types";

    const InitialState = {
};

export default (state = InitialState, actions) => {
  
    switch (actions.type) {
    
        case SET_USER:
            return { ...state, user: actions.payload };
        default:
            return state;
    }
};