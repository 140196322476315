  //fixtures will comme from api
export default  [
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States",
        id:'1'
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'2',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'3',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'4',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'5',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'6',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'7',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'8',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'9',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'10',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'11',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'12',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
    {
        title: "Khat warning for all DRS approved refugees (east africa)",
        date: "12/02/2021",
        id:'13',
        body: "The United States of America has classified KHA Tas a controlled substance. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States. This means it is illegal to bring KHAT to the United States. I f you are caught in possession o f KHA T, you will be subject to fines and/or imprisonment and may jeopardize you and your family's resettlement in the United States"
    },
];