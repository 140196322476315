import React, { useState, useEffect } from 'react';
import './styles/gallery.css';
import Card from '@material-ui/core/Card';
import { Row,Modal, Col,Carousel, Dropdown, Button} from 'antd';
import  {Loader} from '../components/loader.jsx';
import { useSelector } from 'react-redux';
import "antd/dist/antd.css";
import app_free from '../images/app_free.jpeg';
import child from '../images/ch-pic.png';


//fixtures to come from API
var featureResource = {
    title: "USRAP is Free",
    img: app_free,
    pdfUrl: [],
    languages: ['English', 'Kiswahili',],
    body: 'Access to the United States Refugee Admissions Program is FREE. It is expressively forbidden for any RSC Africa employee or partner to accept money or any favors from refugees for any reason. If an RSC Africa employee, or anyone claiming to have access to RSC Africa, demands payment in exchange for their services, please report them immediately to Fraud@CWSAfrica.org'
}

var albums = [
    {
            "id":"1",
            "name":"Album One",
            "gallery":[{
                "id":'1',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'2',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'3',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'4',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'5',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'6',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'7',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'8',
                "url":child,
                "description":"taken on 2021 AGM"
            }
        ]
        },
        {
            "id":"2",
            "name":"Albbum two",
            "gallery":[{
                "id":'1',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'2',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'3',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'4',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'5',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'6',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'7',
                "url":child,
                "description":"taken on 2021 AGM"
            },
            {
                "id":'8',
                "url":child,
                "description":"taken on 2021 AGM"
            }
        ]
        }
        
]

export const Gallery = () => {
    
    const [visible, setVisible] = useState(false);
    //pdf to download state
    const [pdfToDownload, setPdfToDownLoad] = useState("")
    const [languageSelected, setLanguage] = useState('Select Language Download');
    const [pdfDownload, setDownload] = useState('');

    //handle click for languages on each single pdf
    const handleLanguageClick = ({ key }) => {
        setLanguage(key);
    }

    //handle change for languages on each single pdf
    const handleLanguageChange = (pdf_urls) => {
        let search_res;
        let pdf_to_down;
        
        for (var i = 0; i < pdf_urls.length; i++) {
            search_res = pdf_urls[i].toLowerCase().search(languageSelected.toLowerCase());
            if (search_res != -1) {
                pdf_to_down = pdf_urls[i];
                break;
            }
        }
        
        setPdfToDownLoad(pdf_to_down);
    }

    const resetLanguageState = () => {
        setLanguage("Select Language Download")
    };

    //variables to store api data
    const[featuredResource,setfeaturedResource] = useState(featureResource);
    const[gallery,setGallery] = useState(albums);

    // const photos = useSelector((state) => state.gallery.photos);

    

    //api calls here
    const getFeaturedResource = ()=>{
           //ajax call
           //setfeaturedResource(res.data)
    }

     const getGallery = ()=>{
           //ajax call
           //setGallery(res.data)
    }

    useEffect(() => {
         getFeaturedResource();
         getGallery();
         window.scrollTo(0, 0); 
    }, [gallery.length]);


    return (
        <>
            <div className="main-container">
                <Card className='mb-3 mt-1 px-5' >
                    <Row>
                        <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10} >
                            <h1 className="fr-title">Featured Gallery</h1>
                            <Carousel autoplay >
                                {[1,2,3,5,6].map((item,index) =>(
                                    <div key={index.toString()}>
                                        <img src={featuredResource.img} className="fr-image" />
                                    </div>
                                ))}
                            </Carousel>
                        </Col>

                        <Col span={1}></Col>
                        <Col sm={24} xs={24} md={24} lg={10} xl={10} xxl={10}>
                            <p className="ft-title-right">{featuredResource.title}</p>
                            <p>{featuredResource.body} </p>
                        </Col>
                        <Col span={4}></Col>
                    </Row>
                </Card>
               
                <div className='gal-div'> <h1 className="title-gallery">Our Gallery</h1></div>
                {gallery.map((album,index) =>(
                    <div className='gallerys-container' key={index.toString()} >
                        <div>
                            <span className='album-name'>{album.name}</span>
                            <div className="gallery-view">

                            <Row gutter={12} className='gallery-row'>
                                {album.gallery.map((item, index) => (
                                    <Col xs={24} sm={12} md={12} lg={6} xl={6} className="col-image gutter-row" key={index.toString()}>
                                        <img src={item.url} alt={item.id} className='gallery-image' onClick={() => setVisible(true)} />
                                        <Modal
                                            centered
                                            mask={false}
                                            style={{ backgroundColor: "none" }}
                                            visible={visible}
                                            onOk={() => setVisible(false)}
                                            onCancel={() => setVisible(false)}
                                            width={700}
                                            footer={null}>
                                            <Row>
                                                <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                                                    <img src={item.url} alt={item.Id} width="100%" />
                                                </Col>
                                                <Col xs={24} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: "white", position: "relative" }}>
                                                    <p className="img-desc">{item.description}</p>
                                                </Col>
                                            </Row>
                                        </Modal>
                                    </Col>

                                ))}
                            </Row>
                        </div>
                    </div>
                </div>))}
            </div>
        </>
    )
}