import React from 'react'
import "./map.css"
import { VectorMap } from '@south-paw/react-vector-maps';
import africa from './africa.json';
import styled from 'styled-components';

const Map = styled.div`

    VectorMap{
        width:100%;
        hight:100%;
    }

    path {
        fill:#B1B1B1;
        cursor: pointer;
        outline: none;
    }

    path:hover,path[id='ke']:hover,path[id='ug']:hover,path[id='et']:hover,path[id='tz']:hover,path[id='rw']:hover,
    path[id='zm']:hover,path[id='cm']:hover,path[id='bj']:hover,path[id='ne']:hover,
    path[id="ke"]:hover,path[id="ml"]:hover,path[id="lr"]:hover,path[id="gh"]:hover,path[id="cm"]:hover,
    path[id="ao"]:hover,path[id="cf"]:hover,path[id="er"]:hover,path[id="cd"]:hover,path[id="bw"]:hover,
    path[id="na"]:hover,path[id="ng"]:hover,path[id="cm"]:hover,path[id="td"]:hover,path[id="ne"]:hover,
    path[id="mg"]:hover,path[id="eh"]:hover,path[id="sn"]:hover,path[id="gw"]:hover,path[id="sl"]:hover,
    path[id="tz"]:hover,path[id="ug"]:hover,path[id="ml"]:hover,path[id="dz"]:hover,path[id="ci"]:hover,
    path[id="bi"]:hover,path[id="rw"]:hover,path[id="mw"]:hover,path[id="mz"]:hover,path[id="dj"]:hover,
    path[id="gq"]:hover,path[id='eg']:hover,
    path[id="zw"]:hover,path[id="za"]:hover,path[id="ls"]:hover,path[id="bf"]:hover,path[id="tg"]:hover,
    path[id="bj"]:hover,path[id="so"]:hover,path[id="cm"]:hover,path[id="cg"],path[id="gn"],
    path[id="ga"]:hover,path[id="sz"]:hover,
    path[id="et"]:hover,path[id="sd"]:hover,path[id="ss"]:hover,path[id="cd"]:hover,path[id="zm"]:hover {
        fill:rgb(179,179,179);
    }    

    path[id="ke"],path[id="ml"],path[id="lr"],path[id="gh"],path[id="cm"],
    path[id="ao"],path[id="cf"],path[id="er"],path[id="cd"],path[id="bw"],
    path[id="na"],path[id="ng"],path[id="cm"],path[id="td"],path[id="ne"],
    path[id="mg"],path[id="mr"],path[id="sn"],path[id="gw"],path[id="sl"],
    path[id="tz"],path[id="ug"],path[id="ml"],path[id="ci"],
    path[id="rw"],path[id="mw"],path[id="mz"],path[id="dj"],
    path[id="gq"],path[id="ga"],path[id="sz"],
    path[id="zw"],path[id="za"],path[id="ls"],path[id="bf"],
    path[id="bj"],path[id="so"],path[id="cm"],path[id="cg"],path[id="gn"],
    path[id="et"],path[id="sd"],path[id="ss"],path[id="cd"],path[id="zm"] {
        fill:blue;
    }

    path[id='ke'],path[id='ug'],path[id='et'],path[id="bi"],path[id='tz'],path[id='rw'],
    path[id='zm'],path[id='cm'],path[id='bj'],path[id="tg"],path[id='ne'] {
        fill:#1C7EF4;
    }
`;

export const MapSection = () => {

    const [hovered, setHovered] = React.useState('');
    const [figure, setFigure] = React.useState('');
    
    const layerProps = {
        onMouseEnter: ({ target }) => { setHovered(target.attributes.name.value);setFigure(target.attributes.fg?.value)},
        onMouseLeave: () => {setHovered('');setFigure('')},
    }

    console.log('map', hovered);
    
    return (
        <>
            <div className="map-area">
                <div className='map-title'><span>Our Coverage</span></div>
                <div className='map-div'>
                    {hovered && <p className='map-tooltip'>{hovered}<br/>{figure && figure}</p>}
                    <Map><VectorMap className="map" {...africa} layerProps={layerProps}/></Map>
                    <div className="map-label">
                        <div className='label-div'><span className='label1'></span> <span>RSC Africa Coverage Region</span></div>
                        <div  className='label-div'><span className='label2'></span> <span>FY20 Top 10 arrival countries RSC Africa</span></div>
                    </div>
                </div>
            </div>
        </>
    )
}
